import React, {useState, Fragment, useEffect} from 'react';
import "./home.sass";
import '../../assets/styles/ir-black.css';
import Safe from "../../assets/images/safe-cloud.png";
import Highlight from 'react-highlight';
import codeContent from '../pagesContent/home/homeContent';
import contentDescription from '../pagesContent/home/homeDescription';
import Speed from '../../assets/images/home/idea.png'
import Exchange from '../../assets/images/home/money.png'
import Account from '../../assets/images/home/account.png'
import Extend from '../../assets/images/home/extend.png'
import Document from '../../assets/images/home/list.png'
import CommandLine from '../../assets/images/home/software.png'
import Crypto from '../../assets/images/home/crypto.png'
import { ReactTypeformEmbed } from 'react-typeform-embed';
import {Link} from "react-router-dom";

const Home = () => {
    let textInput = React.createRef();

    const openForm=() => {
        console.log(textInput)
        textInput.current.typeform.open();
    };

    const [activeCodeTab, setActiveCodeTab] = useState(codeContent.codeContent.Cards);
    const [activeDescriptionTab, setActiveDescriptionTab] = useState(contentDescription.Cards);
    const [activeTabName, setActiveTabName] = useState("Cards");
    const tabs = ["Cards", "Savings", "Loans", "ACH", "Wyre"];

    const changeCodeAndDescription = (tab) => {
        setActiveCodeTab(codeContent.codeContent[tab]);
        setActiveDescriptionTab(contentDescription[tab]);
        setActiveTabName(tab);
    };
useEffect(()=> {
    document.title = 'Fanclear OÜ';
    document.description = 'We love collecting!';

}, []);

    return (
        <div className='homePage__wrapper'>
            <div className='homePage__top-container'>
                <div className="backs"/>
                <div className="backs2"/>

                <div className="homePage__top-block">

                    <div className="homePage__top-left">
                        <h1>A comic and digital media superstore. </h1>
                        <h3>Coming soon.</h3>
                        {/*<h1>Access the evolution of finance</h1>*/}
                        {/*<h3>World-class tools for blockchain developers, financial services and protocols.</h3>*/}
                        <div className="buttons_block">
                            {/*<Link to={'/developers'}> <button className="white-btn">Developer docs</button></Link>*/}
                            {/*<button className="blue-btn" onClick={()=>openForm()}>Become a partner</button>*/}
                        </div>
                    </div>
                    <div className="homePage__top-right">
                        <img src={Safe} alt="Safe" className="homePage__top-img"/>
                    </div>
                </div>
            </div>


            {/*<div className="homePage__top-block homePage__icons-block">*/}
            {/*   <div className="homePage__iconsLine">*/}
            {/*       <div className="homePage__iconsCell">*/}
            {/*            <img src={CommandLine} alt="speed"/>*/}
            {/*           <h5>Tools</h5>*/}
            {/*           <h4>Developer First</h4>*/}
            {/*           <p>An embeddable widget and white-label API to access*/}
            {/*               regulated finance.</p>*/}
            {/*       </div>*/}
            {/*       <div className="homePage__iconsCell">*/}
            {/*           <img src={Crypto} alt="speed"/>*/}
            {/*           <h5>Get users</h5>*/}
            {/*           <h4>FIAT on Ramps</h4>*/}
            {/*           <p>Directly onboard users from their bank accounts*/}
            {/*               and payment cards.</p>*/}
            {/*       </div>*/}
            {/*       <div className="homePage__iconsCell">*/}
            {/*           <img src={Extend} alt="speed"/>*/}
            {/*           <h5>Scale</h5>*/}
            {/*           <h4>Access Liquidity</h4>*/}
            {/*           <p>Fair rates, aggregated from leading exchanges and*/}
            {/*               OTC desks.</p>*/}
            {/*       </div>*/}
            {/*   </div>*/}
            {/*    <div className="homePage__iconsLine">*/}
            {/*        <div className="homePage__iconsCell">*/}
            {/*            <img src={Document} alt="speed"/>*/}
            {/*            <h5>Regulation</h5>*/}
            {/*            <h4>Compliance</h4>*/}
            {/*            <p>Years of compliance experience and automation.*/}
            {/*                Don't start from scratch.</p>*/}
            {/*        </div>*/}
            {/*        <div className="homePage__iconsCell">*/}
            {/*            <img src={Exchange} alt="speed"/>*/}
            {/*            <h5>Exchange</h5>*/}
            {/*            <h4>FX and Crypto</h4>*/}
            {/*            <p>Making markets since 2013, FNBD provides*/}
            {/*                best-in-class pricing & execution.</p>*/}
            {/*        </div>*/}
            {/*        <div className="homePage__iconsCell">*/}
            {/*            <img src={Speed} alt="speed"/>*/}
            {/*            <h5>Speed</h5>*/}
            {/*            <h4>Focus on Buidl</h4>*/}
            {/*            <p>We’ve done the boring stuff, so you can focus on*/}
            {/*                making something awesome.</p>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*    <div className="homePage__top-left">*/}
            {/*        <div className="homePage__codeTabs-container">*/}
            {/*            {tabs.map((tab, index) =>*/}
            {/*                <Fragment key={index}>*/}
            {/*                    <div*/}
            {/*                        onClick={() => changeCodeAndDescription(tab)}*/}
            {/*                        className={activeTabName === tab*/}
            {/*                            ? "homePage__activeTab"*/}
            {/*                            : "homePage__noActiveTab"*/}
            {/*                        }*/}
            {/*                    >{tab}</div>*/}
            {/*                </Fragment>*/}
            {/*            )}*/}
            {/*        </div>*/}
            {/*        <Highlight className='hljs' style={{"padding": "20px !important"}}>*/}
            {/*            {activeCodeTab}*/}
            {/*        </Highlight>*/}
            {/*    </div>*/}
            {/*    <div className="homePage__middle-right">{activeDescriptionTab}</div>*/}
            {/*</div>*/}

            {/*<div className='homePage__backline-container'>*/}
            {/*    <div className="backs-revert"/>*/}
            {/*    <div className="backs2-revert"/>*/}

            {/*    <div className="homePage__backline-block">*/}

            {/*        <div className="homePage__top-left">*/}
            {/*            <h4 className="h4-no-paddings">FNBD Wydget</h4>*/}
            {/*            <h1>Onboard users with an easy-to-embed web module</h1>*/}
            {/*            <h3>With the FNBD SDK, applications get a compliant, regulated and easy to use fiat onramp.*/}
            {/*                Take customer fiat deposits directly onto your platform from all geographies where FNBD operates*/}
            {/*                — all this in under 10 lines of code.</h3>*/}
            {/*            <div className="buttons_block">*/}
            {/*                <Link to={'/developers'}> <button className="white-btn">Developer docs</button></Link>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="homePage__top-right">*/}
            {/*            <img src={Safe} alt="Safe" className="homePage__top-img"/>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}


            {/*<div className="homePage__code-block">*/}
            {/*    <div className="homePage__top-left">*/}
            {/*        <h4 className="h4-no-paddings dark-color"></h4>*/}
            {/*        <h1 className="dark-color">Customise with our API</h1>*/}
            {/*        <h3 className="dark-color">With the FNBD API, partners have full control over all aspects of users flows and UX.*/}
            {/*            You will be able to provide on/off ramps, payment processing, liquidity and compliance natively on your application.</h3>*/}
            {/*        <div className="buttons_block">*/}
            {/*            <Link to={'/developers'}> <button className="blue-btn">Developer docs</button></Link>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="homePage__top-right">*/}
            {/*        <Highlight className='hljs code-class' style={{"padding": "20px !important"}}>*/}
            {/*            {codeContent.codeContent.code}*/}
            {/*        </Highlight>*/}
            {/*    </div>*/}




            {/*</div>*/}

            {/*<div className='homePage__top-container' style={{"marginTop": "120px"}}>*/}
            {/*    <div className="backs"/>*/}
            {/*    <div className="backs2"/>*/}

            {/*    <div className="homePage__top-block">*/}

            {/*        <div className="homePage__top-left">*/}
            {/*            <h1>Focus. On. Buidling.</h1>*/}
            {/*            <h3>We're serious about helping the crypto community and have been active since 2013.*/}
            {/*                Focusing on compliance, licensing and legal fees shouldn’t be what creators are doing. They should be creating.</h3>*/}

            {/*        </div>*/}
            {/*        <div className="homePage__top-right" style={{"flexDirection": "column"}}>*/}
            {/*            <p>Traders are able to exchange fiat currency (USD, GBP, EUR, AUD) for cryptocurrency (ETH) — all within their self-custodied wallet using the AirSwap trading interface.</p>*/}

            {/*            <div className="buttons_block">*/}
            {/*                <Link to={'/developers'}> <button className="white-btn">Developer docs</button></Link>*/}
            {/*                <button className="blue-btn" onClick={()=>openForm()}>Learn more</button>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*<div className="homePage__bottom-block background-line">*/}

            {/*    <h2>Ready To Get Started?</h2>*/}
            {/*    <p>Contact our team to become a partner and go to market in 6 hours, not 6 months.</p>*/}
            {/*    <div className="buttons_block-bottom">*/}
            {/*        <Link to={'/developers'}> <button className="white-btn-border">View Api</button></Link>*/}
            {/*        <button className="blue-btn">Contact Us</button>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*<ReactTypeformEmbed*/}
            {/*    popup*/}
            {/*    autoOpen={false}*/}
            {/*    url="https://eyasnij.typeform.com/to/gc1X6GuN"*/}
            {/*    hideHeaders*/}
            {/*    hideFooter*/}
            {/*    buttonText="Go!"*/}
            {/*    style={{ top: 100 }}*/}
            {/*    ref={textInput}*/}
            {/*/>*/}


        </div>
    );
};

export default Home;
