import './App.css';
import React from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Home from '../src/pages/home/home'
import Price from '../src/pages/price/price'
import Developers from '../src/pages/developers/developers'
import ProductsPageGenerator from '../src/pages/productsPageGenerator/productsPageGenerator'
import Partner from '../src/pages/partner/partner'
import Privacy from '../src/pages/privacy/privacy'
import Header from '../src/components/header/header'
import Footer from '../src/components/footer/footer'

function App() {
  return (
      <div className='mainPage__wrapper'>
        <BrowserRouter>
          <Header/>
          <Switch>
            <Route path='/' exact>
             <Home/>
            </Route>
            {/*<Route path='/issue-cards' exact>*/}
            {/*  <ProductsPageGenerator pageName="IssueCards"/>*/}
            {/*</Route>*/}
            {/*  <Route path='/card-processing' exact>*/}
            {/*  <ProductsPageGenerator pageName="CardProcessing"/>*/}
            {/*</Route>*/}
            {/*  <Route path='/checks' exact>*/}
            {/*  <ProductsPageGenerator pageName="Checks"/>*/}
            {/*</Route>*/}
            {/*  <Route path='/ach' exact>*/}
            {/*  <ProductsPageGenerator pageName="ACH"/>*/}
            {/*</Route>*/}
            {/*<Route path='/wires' exact>*/}
            {/*  <ProductsPageGenerator pageName="Wires"/>*/}
            {/*</Route>*/}
            {/*<Route path='/rdc' exact>*/}
            {/*  <ProductsPageGenerator pageName="RDC"/>*/}
            {/*</Route>*/}
            {/*<Route path='/deposit-accounts' exact>*/}
            {/*  <ProductsPageGenerator pageName="DepositAccounts"/>*/}
            {/*</Route>*/}
            {/*<Route path='/savings-accounts' exact>*/}
            {/*  <ProductsPageGenerator pageName="SavingsAccounts"/>*/}
            {/*</Route>*/}
            {/*<Route path='/fbo-accounts' exact>*/}
            {/*  <ProductsPageGenerator pageName="FBOAccounts"/>*/}
            {/*</Route>*/}
            {/*<Route path='/custody-accounts' exact>*/}
            {/*  <ProductsPageGenerator pageName="CustodyAccounts"/>*/}
            {/*</Route>*/}
            {/*<Route path='/clearing-accounts' exact>*/}
            {/*  <ProductsPageGenerator pageName="ClearingAccounts"/>*/}
            {/*</Route>*/}
            {/*<Route path='/auth' exact>*/}
            {/*  <ProductsPageGenerator pageName="Auth"/>*/}
            {/*</Route>*/}
            {/*<Route path='/sync' exact>*/}
            {/*  <ProductsPageGenerator pageName="Sync"/>*/}
            {/*</Route>*/}
            {/*<Route path='/data-enrichment' exact>*/}
            {/*  <ProductsPageGenerator pageName="DataEnrichment"/>*/}
            {/*</Route>*/}
            {/*<Route path='/crypto-wallets' exact>*/}
            {/*  <ProductsPageGenerator pageName="CryptoWallets"/>*/}
            {/*</Route>*/}
            {/*<Route path='/one-time-loans' exact>*/}
            {/*  <ProductsPageGenerator pageName="OneTimeLoans"/>*/}
            {/*</Route>*/}
            {/*<Route path='/revolving-loans' exact>*/}
            {/*  <ProductsPageGenerator pageName="RevolvingLoans"/>*/}
            {/*</Route>*/}
            {/*<Route path='/price' exact>*/}
            {/*  <Price/>*/}
            {/*</Route>*/}
            {/*<Route path='/developers' exact>*/}
            {/*  <Developers/>*/}
            {/*</Route>*/}
            {/*<Route path='/partner' exact>*/}
            {/*  <Partner/>*/}
            {/*</Route>*/}
            {/*  <Route path='/privacy' exact>*/}
            {/*  <Privacy/>*/}
            {/*</Route>*/}

            {/*<Redirect to='/'/>*/}
          </Switch>
          {/*<Footer/>*/}
        </BrowserRouter>

      </div>
  );
}

export default App;
