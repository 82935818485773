import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import HeaderProducts from "../header/products/products"
import "./header.sass";
import Logo from "../../assets/images/fanclear.png"
import DownArrow from "../../assets/images/down-arrow.png"

const Header = () => {
    const [openMenu, setOpenMenu] = useState(false);
    const [dropDown, setDropDown] = useState(false);
    return (
        <div className='header__container'>
            <div className='header__block '>
                <div className='header__block-left'>
                    <Link to={'/'}>
                        <img src={Logo} alt="logo" className='header__block-img'/>
                    </Link>
                </div>
                {/*<div className='right-menu' onClick={()=> {*/}
                {/*    setOpenMenu(!openMenu)*/}
                {/*}}>*/}
                {/*<div id={openMenu?'sandwich' :'sandwich-1'}>*/}
                {/*    <div className='sw-topper'></div>*/}
                {/*    <div className='sw-bottom'></div>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*<div className='header__block-right products-dropdown'>*/}
                {/*    <div className='header__menu-container'>*/}
                        {/*<div className='header__menu-title products-title'>*/}
                        {/*    Products*/}
                        {/*    <img src={DownArrow} alt="arrow down"/>*/}
                        {/*    <HeaderProducts/>*/}
                        {/*</div>*/}
                {/*        <div className='header__menu-title link-title'>*/}
                {/*            <Link to={'/price'}>Price</Link></div>*/}
                {/*        <div className='header__menu-title link-title'>*/}
                {/*            <Link to={'/developers'}>Developers</Link>*/}
                {/*        </div>*/}

                {/*        <div className='header__menu-title link-title'>Individuals</div>*/}
                {/*        <div className='header__menu-title link-title'>*/}
                {/*            <Link to={'/partner'}>Partners</Link></div>*/}
                {/*        <div className='header__menu-title link-title'>Talk to Us</div>*/}
                {/*        <div className='header__menu-title link-title'>Log In</div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
            {/*{openMenu &&*/}
            {/*    <div className='header__block-right mobile-menu'>*/}
            {/*        <div className='header__menu-container'>*/}
            {/*            <div className='header__menu-title ' onClick={()=>setDropDown(!dropDown)}>*/}
            {/*                Products*/}
            {/*                <img src={DownArrow} alt="arrow down"/>*/}
            {/*                {dropDown && <HeaderProducts mobile={true}/>}*/}
            {/*            </div>*/}
            {/*            <div className='header__menu-title'>API</div>*/}
            {/*            <div className=' products-title'>*/}
            {/*                <Link to={'/price'}>Price</Link></div>*/}
            {/*            <div className='header__menu-title'>About</div>*/}
            {/*            <div className='header__menu-title'>Blog</div>*/}
            {/*            <div className='header__menu-title'>Contact</div>*/}
            {/*            <div className='header__menu-title'>Dashboard</div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*}*/}
        </div>
    );
};

export default Header;
