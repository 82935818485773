import React from "react";

let contentDescription = {
    Cards: <div className="homePage__right-description">
        <h2>Issue Cards</h2>
        <p> Issue and service white-labeled Cards for individual and business accounts.
            Our customizable virtual or physical Cards are FDIC insured, with dynamic spending limits and zero
            incremental fees.</p></div>,
    Savings: <div className="homePage__right-description">
        <h2> Open Savings Accounts</h2>
        <p>Enable users to earn interests on their funds with high-yield Savings Accounts.
            Choose between Savings Deposit Accounts or Savings Sub-Accounts. Interest is accrued daily and paid
            monthly.</p>
    </div>,
    Loans: <div className="homePage__right-description">
        <h2>Issue One-Time Loans</h2>
        <p>Service and Issue One-Time Loans for your customers with our developer-friendly APIs.
            One-Time Loans are highly customizable with capped APR accrued interest and no late fees.</p>
    </div>,
    ACH: <div className="homePage__right-description">
        <h2>Enable Payments via ACH</h2>
        <p>Build a customized payment flow to enable ACH payments or to connect and verify a user’s ACH account.
            We offer Regular ACH and Same-Day ACH. Both types support debit and credit transactions.</p>
    </div>,
    Fanclear: <div className="homePage__right-description">
        <h2>Send Wire Payments</h2>
        <p> Enable support for Domestic and International Wires with our automated back office.
            Wire transfers are verified and released to the Federal Reserve in real time and settle the same day.</p>
    </div>
};

export default contentDescription
